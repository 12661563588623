import { useDispatch, useSelector } from "react-redux";

import { clearRecoveryCode, clearRecoveryData, clearErrorCodeMessage, onLoadUser,
    onErrorCodeData, onChangePassword, onCodePassword, 
    onErrorUserData, clearErrorUserMessage, onLoadCompany, onCodeVerification, onErrorCodeVerification, clearVerificationCode, } from 'store/safeentry';
import { userApi, localityApi } from "api";

import { useAuthStore } from "./useAuthStore";


import { onLogin, onChecking, onLogout, clearErrorMessage } from "store/auth";

export const useUserStore = () => {
    const dispatch = useDispatch();
    const { status, user, errorMessage, apiLoginInfo } = useSelector(state => state.auth);
    const { statusUserCode, statusUserLoad, errorCodeMessage, errorUserMessage, 
        apiCompanyData, apiPersonData, apiPasswordData, apiCodePassword, errorCodeVerificationMessage, apiCodeVerification } = useSelector(state => state.user);


    const clearRecoveryPage = async () => {
        dispatch(clearRecoveryData());
    }

    const clearCodePage = async () => {
        dispatch(clearRecoveryCode());
    }

    const loadResidentIDData = async ({ identification_type, identification }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await userApi.post('person/search', { identification_type, identification }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            //console.log(data)
            dispatch(onLoadUser({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorUserData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorUserMessage());
            }, 10);
        }
    }

    const sendEmail = async (user_email) => {
        try {
            const { data } = await userApi.get(`password/recovery/${user_email}`, {
            });
            //console.log(data)
            dispatch(onChangePassword({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorUserData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorUserMessage());
            }, 10);
        }
    }

    const passwordCode = async ({ user_mail, password, code  }) => {
        try {
            const { data } = await userApi.post('user/password/set', { user_mail, password, code }, {
                
            });
            //console.log(data)
            dispatch( onCodePassword({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorCodeData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorCodeMessage());
            }, 10);
        }
    }

    const loadCompanyData = async ({ user_uuid, enterprise_uuid }) => {
        try {
            let bear_token = localStorage.getItem('token')
            const { data } = await userApi.post('user/enterprise/company/list', { user_uuid, enterprise_uuid }, {
                headers: {
                    'Security-Token': bear_token
                }
            });
            dispatch(onLoadCompany({ data: data.data }));
            //console.log(data.data)
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            dispatch(onErrorUserData(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearErrorUserMessage());
            }, 10);
        }
    }

    //Nuevo Servicio para el segundo factor de autenticion
    const verificationCode = async ({ user_name, password, origen_ip, access_type, access_code  }) => {
        try {
            const { data } = await userApi.post('login/verification', { user_name, password, origen_ip, access_type, access_code  });
            console.log(data)
            console.log(data.data.token)
            localStorage.setItem('token', data.data.token);
            localStorage.setItem('token-init-date', new Date().getTime());
            localStorage.setItem("key1", data.data.enterprise.data);
            localStorage.setItem("key2", data.data.company.data);
            localStorage.setItem("key3", data.data.label_catalog.data);
            localStorage.setItem("rolUser", data.data.user_mocoli);
            localStorage.setItem("user", data.data.user_uuid);
            await getLocalityCatalog(data.data.token);
            dispatch(onLogin({ token: data.data.token, user_uuid: data.data.user_uuid}));
            dispatch( onCodeVerification ({ data: data.data }));
        } catch (error) {
            // error.response.data?.details_error || error.response.data?.message || 
            console.log(error)
            dispatch(onErrorCodeVerification(error.response.data?.details_error || error.response.data?.message || 'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
            setTimeout(() => {
                dispatch(clearVerificationCode ());
            }, 10);
        }
    }

    const getLocalityCatalog = async (bearer_token) => {
        const { data } = await localityApi.get(`locality/catalog`, {
          headers: {
            'Security-Token': bearer_token
          }
        });
        console.log(data)
        localStorage.setItem("locality_catalog", JSON.stringify(data.data));
      }

    return {
        // * Properties
        statusUserLoad,
        errorUserMessage,
        apiPersonData,
        apiPasswordData,
        apiCodePassword,
        apiCompanyData,
        statusUserCode,
        errorCodeMessage,
        errorCodeVerificationMessage, 
        apiCodeVerification,

        // * Methods
        loadCompanyData,
        loadResidentIDData,
        sendEmail,
        passwordCode,
        clearRecoveryPage,
        clearCodePage,
        verificationCode
    }

}