import React from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import { Grid } from "@mui/material";

// Telerik
import { Checkbox } from "@progress/kendo-react-inputs";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from '@progress/kendo-react-indicators';
import { NotificationGroup, Notification } from '@progress/kendo-react-notification';

//Components
import { FormLogin } from './FormLogin';
import { CodeRecoveryPage } from './CodeRecoveryPage';
import LoginPage from './LoginPage';



//hooks
import { useUserStore } from "hooks/useUserStore";
import { red } from '@mui/material/colors';
import { SecondFactorPage } from "./SecondFactorPage";

export const RecoveryPasswordPage = () => {

    const position = {
        bottomRight: {
            bottom: 0,
            right: 0,
            alignItems: "flex-end",
        },
        topRight: {
            top: 5,
            left: "95%",
            alignItems: "flex-end",
        },
        bottomLeft: {
            bottom: 0,
            left: "92%",
            alignItems: "flex-start",
        },
    }

    const { clearRecoveryPage, sendEmail, apiPasswordData, errorUserMessage, statusUserLoad, statusUserCode } = useUserStore();
    const [recoveryPasswordForm, setRecoveryPasswordForm] = React.useState(true)
    const [loginForm, setLoginForm] = React.useState(false)
    const [recoveryAction, setRecoveryAction] = React.useState(false)
    const [codeRecoveryAction, setCodeRecoveryAction] = React.useState(false)
    const [buttonState, setButtonState] = React.useState(true)
    const [buttonBackState, setButtonBackState] = React.useState(true)
    const [loadingState, setLoadingState] = React.useState(false)
    const [email, setEmail] = React.useState()
    const [content, setContent] = React.useState();

    const handleChangeEmail = (event) => {
       // console.log(event.target.value)
        setEmail(event.target.value)
    }

    const handleBackLogin = () => {
        setLoginForm(true)
        setRecoveryAction(true)
        setRecoveryPasswordForm(false)
    }

    const handleRecovery = (event) => {
        event.preventDefault()
        setButtonBackState(false)
        setButtonState(false)
        setLoadingState(true)
        sendEmail(email);
        //console.log(statusUserLoad);

    }

    const handleChangePassword = () => {
        //console.log("Ingresa al cambio de pantalla")
        setLoginForm(false)
        setRecoveryAction(false)
        setRecoveryPasswordForm(true)
        
    }

    const [notification, setNotification] = React.useState(false)
    const handleContinueRecovery = () => {
        setCodeRecoveryAction(true)
        setLoginForm(false)
        setRecoveryAction(false)
        setRecoveryPasswordForm(false)
    }

    const viewNotification = (state) => {
        setNotification(state)
    }

    React.useEffect(() => {
        if (errorUserMessage !== undefined) {
            setContent("Correo Electrónico No Registrado")
            setButtonBackState(true)
            setButtonState(true)
            setLoadingState(false)
            setCodeRecoveryAction(false)
            setRecoveryPasswordForm(true)
            console.log(errorUserMessage)
            setNotification(true)
            setTimeout(() => {
                viewNotification(false)
            }, 4000);
        }
    }, [errorUserMessage])

    // React.useEffect(() => {
    //     if (apiPasswordData?.data.resultado === 1) {
    //         console.log(apiPasswordData?.data)
    //         console.log(apiPasswordData?.data.resultado)
    //         //if (apiPasswordData?.data.resultado)
    //         setCodeRecoveryAction(true)
    //         //         // setLoginForm(false)
    //         //         // setRecoveryAction(false)
    //         setRecoveryPasswordForm(false)
    //     }
    // }, [apiPasswordData])

    React.useEffect(() => {
        if (statusUserLoad === '1') {
            //console.log("Cambiar apantalla")
            setCodeRecoveryAction(true)
            setLoadingState(false)
            // setLoginForm(false)
            // setRecoveryAction(false)
            setRecoveryPasswordForm(false)
            clearRecoveryPage();
        }
    }, [statusUserLoad])

    return (
        <>
            <div className="row">
                <div className="offset-sm-12">
                    {recoveryPasswordForm && (
                        <form className="k-form" onSubmit={handleRecovery} >
                            <div>
                                <MDTypography
                                    // to="/safeentry/recovery"
                                    variant="h3"
                                    color="light"
                                    fontWeight="medium"
                                    textGradient
                                >
                                    Restablecer Contraseña
                                </MDTypography>
                                <br />
                                <br />
                                <MDTypography
                                    // to="/safeentry/recovery"
                                    variant="h6"
                                    color="light"
                                    fontWeight="medium"
                                    textGradient
                                >
                                    Ingrese su usuario y le enviaremos un correo electrónico para restablecer su contraseña
                                </MDTypography>
                                <br />
                                <br />
                                <MDTypography
                                    // to="/safeentry/recovery"
                                    variant="button"
                                    color="light"
                                    fontWeight="medium"
                                    textGradient
                                >
                                    Usuario
                                </MDTypography>

                                <Input
                                    validityStyles={false}
                                    name="email"
                                    type="email"
                                    style={{
                                        width: "100%",
                                        color: "rgb(255, 255, 255)",
                                        backgroundColor: "rgb(102, 105, 98, 0.6)",
                                        borderRadius: "15px",
                                        borderColor: "rgb(255, 255, 255, 0.3)",
                                    }}
                                    required
                                    //value={email}
                                    onChange={handleChangeEmail}
                                    validationMessage="Obligatorio"
                                />
                                {notification && (
                                    <NotificationGroup
                                        style={
                                            position.bottomRight
                                        }>
                                        <Notification
                                            key="error"
                                            type={{
                                                style: "error",
                                                //margin: "center"
                                            }}

                                            closable={true}
                                        //onClose={() => viewNotification(false)}
                                        >
                                            {content}
                                        </Notification>

                                    </NotificationGroup>
                                )
                                }
                            </div>
                            <br />
                            <div className="k-form-buttons">
                                {buttonState && (
                                    <Button
                                        style={{
                                            width: "100%",
                                            height: "42px",
                                            color: "rgb(255, 255, 255)",
                                            backgroundColor: "rgb(0, 0, 0)",
                                            borderRadius: "15px",
                                            borderColor: "rgb(102, 105, 98, 0.6)",
                                        }}
                                        //className="buttons-container-button"
                                        // themeColor={"dark"}
                                        size="small"
                                        type="submit"
                                    //onClick={handleContinueRecovery}
                                    //disabled={disabledButton}
                                    >
                                        CONTINUAR
                                    </Button>

                                )}
                                {buttonBackState && (
                                    <Button
                                        style={{
                                            width: "100%",
                                            height: "42px",
                                            color: "rgb(0, 0, 0)",
                                            backgroundColor: "rgb(255, 255, 255)",
                                            borderRadius: "15px",
                                            borderColor: "rgb(102, 105, 98, 0.6)",
                                        }}
                                        //className="buttons-container-button"
                                        // themeColor={"dark"}
                                        size="small"
                                        //type="submit"
                                        //disabled={disabledButton}
                                        onClick={handleBackLogin}
                                    >
                                        REGRESAR
                                    </Button>
                                )}

                                {loadingState && (
                                    <Loader
                                        style={{
                                            width: "100%",
                                            height: "42px",
                                            color: "rgb(255, 255, 255)",
                                            paddingLeft: "45%"
                                            //backgroundColor: "rgb(0, 0, 0)",
                                        }}
                                        size="medium"
                                        type={"converging-spinner"}
                                    />
                                )}
                            </div>
                        </form>
                    )}
                    {/* {recoveryPasswordForm && (
                        <SecondFactorPage user_email={email} />
                    )} */}

                    {loginForm && (
                        <FormLogin />
                    )}
                    {recoveryAction && (
                        <Grid container >
                            <Grid item xs={12} md={4} lg={4}>
                                <MDBox mt={3} textAlign="left">
                                    <Checkbox id={"chb1"} disabled={true} checked={true}>
                                        <MDTypography variant="button" color="text">
                                            <MDTypography
                                                variant="button"
                                                color="light"
                                                fontWeight="medium"
                                                textGradient
                                            >
                                                &nbsp;&nbsp;Recordarme
                                            </MDTypography>
                                        </MDTypography>
                                    </Checkbox>
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={8} lg={8}>
                                <MDBox mt={3} textAlign="right">
                                    <Button fillMode="flat" >
                                        <MDTypography variant="button" color="text" onClick={handleChangePassword} >
                                            <MDTypography
                                                //component={Link}
                                                //to="/safeentry/recovery"
                                                variant="button"
                                                color="light"
                                                fontWeight="medium"
                                                textGradient
                                            >
                                                ¿Olvidaste tu contraseña?
                                            </MDTypography>
                                        </MDTypography>
                                    </Button>
                                </MDBox>
                            </Grid>
                        </Grid>)}

                    {codeRecoveryAction && (
                        <CodeRecoveryPage user_email={email} />
                        
                    )}
                </div>
            </div>
        </>
    )
}
