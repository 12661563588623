import React from 'react'
// react-router-dom components
import { Link } from "react-router-dom";
//import Footer from "examples/Footer";
import Footer from "./FooterLogin";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import { Button, Grid } from "@mui/material";
import { Card } from "@mui/material";
// Authentication layout components
import IllustrationLayout from "./IllustrationLayout";

// Telerik
import { Checkbox } from "@progress/kendo-react-inputs";

import bgImage from "assets/images/safe-entry/login_new.jpg";
import { FormLogin } from './FormLogin';
import { RecoveryPasswordPage } from './RecoveryPasswordPage';
import { CodeRecoveryPage } from './CodeRecoveryPage';
import BackgroundSlider from './BackgroundSlider';
import test_slider_1 from "assets/images/safe-entry/test_slider_1.jpg"
import test_slider_2 from "assets/images/safe-entry/test_slider_2.jpg"
import test_slider_3 from "assets/images/safe-entry/test_slider_3.jpg"

export const LoginPage = () => {
  //Borrar el Local Storage cuando se ingresa al sistema
  localStorage.clear();


  //componentes del login
  const [changePasswordForm, setChangePasswordForm] = React.useState(false)
  const [loginForm, setLoginForm] = React.useState(true)
  const [recoveryForm, setRecoveryForm] = React.useState(false)
  const [recoveryAction, setRecoveryAction] = React.useState(true)
  const [codeForm, setCodeForm] = React.useState(false)
  const [emailRecovery, setemailRecovery] = React.useState(undefined)

  const handleChangePassword = () => {
    //console.log("Ingresa al cambio de pantalla")
    setLoginForm(false)
    setRecoveryAction(false)
    setRecoveryForm(true)
  }

  const handleSubmitRecovery = (event) => {
    setemailRecovery(event)
    event.preventDefault();
    //console.log(event)
  }

  return (
    <>
      {/* <BackgroundSlider images={[bgImage, test_slider_1, test_slider_2, test_slider_3]} />  */}
      <IllustrationLayout
        title="SAFE ENTRY"
        description="Ingrese su correo electrónico y contraseña para iniciar sesión"
        illustration={bgImage}
      >
        <>
          <MDBox py={2}  >
            {loginForm && (
              <FormLogin setRecoveryAction={setRecoveryAction} />
            )}

            {recoveryAction && (
              <Grid container >
                <Grid item xs={12} md={4} lg={4}>
                  <MDBox mt={3} textAlign="left">
                    <Checkbox id={"chb1"} disabled={true} checked={true}>
                      <MDTypography variant="button" color="text">
                        <MDTypography
                          variant="button"
                          color="light"
                          fontWeight="medium"
                          textGradient
                        >
                          &nbsp;&nbsp;Recordarme
                        </MDTypography>
                      </MDTypography>
                    </Checkbox>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                  <MDBox mt={3} textAlign="right">
                    <Button>
                      <MDTypography variant="button" color="text" onClick={handleChangePassword} >
                        <MDTypography
                          variant="button"
                          color="light"
                          fontWeight="medium"
                          textGradient
                        >
                          ¿Olvidaste tu contraseña?

                        </MDTypography>
                      </MDTypography>
                    </Button>
                  </MDBox>
                </Grid>
              </Grid>
            )}

            {recoveryForm && (
              <RecoveryPasswordPage />
            )}

            
          </MDBox>
        </>
      </IllustrationLayout>
    </>
  );
};

export default LoginPage;
