import { useDispatch, useSelector } from "react-redux"

import { userApi, localityApi } from "api";
import { onLogin, onChecking, onLogout, clearErrorMessage } from "store/auth";

export const useAuthStore = () => {

  const { status, user, errorMessage, apiLoginInfo } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const startLogin = async ({ user_name, password, origen_ip, access_type }) => {
    
    try {
      const { data } = await userApi.post('login', { user_name, password, origen_ip, access_type });
      //console.log(data);
      dispatch(onChecking({ data: data}));
      if (data.status === 200)
        {
          localStorage.setItem('token', data.data.token);
          localStorage.setItem('token-init-date', new Date().getTime());
          localStorage.setItem("key1", data.data.enterprise.data);
          localStorage.setItem("key2", data.data.company.data);
          localStorage.setItem("key3", data.data.label_catalog.data);
          localStorage.setItem("rolUser", data.data.user_mocoli);
          localStorage.setItem("user", data.data.user_uuid);
          await getLocalityCatalog(data.data.token);
          //dispatch(onLogin({ token: data.data.token, user_uuid: data.data.user_uuid}));
          //console.log(data.data.enterprise.data)
      }
      
    } catch (error) {
      // error.response.data?.details_error || error.response.data?.message ||
      dispatch(onLogout( error.response.data?.details_error || error.response.data?.message ||'Lo sentimos. Algunos de nuestros procesos no funcionó.'));
      setTimeout(() => {
        dispatch(clearErrorMessage());
      }, 10);
    }
  }

  const getLocalityCatalog = async (bearer_token) => {
    const { data } = await localityApi.get(`locality/catalog`, {
      headers: {
        'Security-Token': bearer_token
      }
    });
    console.log(data)
    localStorage.setItem("locality_catalog", JSON.stringify(data.data));
  }

  // Todo: Esto es para poder valdiar el token
  // const checkAuthToken = async () => {
  //   const token = localStorage.getitem('token');
  //   if ( !token) return dispatch(onLogout());
  //   try {
  //     const { data } = await userApi.get('auth/renew');
  //     localStorage.setItem('token', data.token);
  //     localStorage.setItem('token-init-date', new Date().getTime());
  //     dispatch(onLogin());
  //   }catch (error) {
  //     localStorage.clear();
  //     dispatch(onLogout());
  //   }
  // }

  const startLogout = () => {
    localStorage.clear();
    dispatch(onLogout());
  }

  return {
    // * Propiedades
    status,
    user,
    errorMessage,
    apiLoginInfo,


    //* Métodos
    startLogin,
    startLogout,
  }
}